import { Box, Button, Typography } from "@mui/material";
import Map from "./GoogleMap/Map";
import { googleMapsUrl } from "../contants/mapConstants";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const phoneNumber = "+19452531019";

  const handleWhatsAppClick = () => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, "_blank");
  };

  const handleInstagramClick = (instagramUsername: string) => {
    const url = `https://www.instagram.com/${instagramUsername}/`;
    window.open(url, "_blank");
  };
  return (
    <Box
      id={"findUs-section"}
      sx={{
        backgroundColor: "#000",
        paddingTop: "100px",
      }}
    >
      <Typography
        sx={{
          color: "#fff",
          fontSize: "50px",
          textAlign: "center",
        }}
      >
        Find Us
      </Typography>
      <Box
        py={"50px"}
        sx={{
          maxWidth: "80%",
          margin: "auto",
          display: "flex",
          justifyContent: "space-around",
          gap: "25px",

          "@media (max-width: 768px)": {
            maxWidth: "100%",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "30px",
            }}
          >
            Address
          </Typography>
          <Button
            onClick={() => window.open(googleMapsUrl, "_blank")}
            sx={{
              textAlign: "start",
            }}
          >
           Select Clip, 6900 Alma Dr suite 130, Plano
            <br /> TX 75023, United States
          </Button>
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "30px",
            }}
          >
            Contact
          </Typography>
          <Button
            onClick={() => handleWhatsAppClick()}
            sx={{
              textAlign: "start",
              marginRight: "auto",
            }}
          >
            <LocalPhoneIcon
              sx={{
                marginRight: "10px",
                marginLeft: 0,
              }}
            />
            +1 (945) 253-1019
          </Button>
          <Button
            onClick={() => handleInstagramClick("elio__sallon")}
            sx={{
              textAlign: "start",
              marginRight: "auto",
            }}
          >
            <InstagramIcon
              sx={{
                marginRight: "10px",
              }}
            />
            elio__sallon
          </Button>
          <Button
            onClick={() => handleInstagramClick("jolas_hairstylist")}
            sx={{
              textAlign: "start",
              marginRight: "auto",
            }}
          >
            <InstagramIcon
              sx={{
                marginRight: "10px",
              }}
            />
            jolas_hairstylist
          </Button>
          <Button
            onClick={() => handleInstagramClick("select_clip")}
            sx={{
              textAlign: "start",
              marginRight: "auto",
            }}
          >
            <InstagramIcon
              sx={{
                marginRight: "10px",
              }}
            />
            select_clip
          </Button>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "30px",
            }}
          >
            Open Time
          </Typography>
          <Box>
            <Typography
              sx={{
                color: "#fff",
              }}
            >
              Tuesday - Saturday: 10am - 8pm
            </Typography>
            <Typography
              sx={{
                color: "#fff",
              }}
            >
              Sunday : 11am - 6pm
            </Typography>
            <Typography
              sx={{
                color: "#fff",
              }}
            >
              Monday : Closed
            </Typography>
          </Box>
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Map />
      </div>
    </Box>
  );
};

export default Footer;
