import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import videomp4 from "../../assets/video/select.mp4";
import videowebm from "../../assets/video/select.webm";
import videoogv from "../../assets/video/select.ogv";

import {
  cl1,
  cl3,
  cl4,
  cl6,
  cl8,
  cl9,
  cl11,
  cl12,
  cl13,
  cl14,
  cl15,
  cl16,
  cl17,
  cl18,
  cl19,
  cl20,
  cl21,
  cl22,
  cl23,
  cl24,
  cl25,
  cl1_w,
  cl2_w,
  cl3_w,
  cl4_w,
  cl5_w,
  cl6_w,
  cl7_w,
  cl8_w,
  cl9_w,
  cl10_w,
  cl11_w,
  cl12_w,
  cl13_w,
  cl14_w,
  cl15_w,
  cl16_w,
  cl17_w,
  cl18_w,
  shop,
  owners,
} from "../../assets/images/gallery";

const Gallery = () => {
  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.01 } // Adjusted threshold
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  function srcset(image: string, size: number, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${
        size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }

  return (
    <Box
      ref={observerRef}
      id="gallery-seciton"
      sx={{
        bgcolor: "#000",
      }}
    >
      <Typography
        sx={{
          fontSize: "50px",
          fontWeight: "700",
          textAlign: "center",
          color: "#fff",
          padding: "50px",
        }}
      >
        Gallery
      </Typography>

      <ImageList
        sx={{
          width: "100%",
          height: "auto",
          backgroundColor: "#000",
          mb: 0,
          borderRadius: "100px",
        }}
        variant="quilted"
        cols={3}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
          }}
        >
          <video
            width="100%"
            height="100%"
            style={{
              objectFit: "cover",
            }}
            autoPlay
            loop
            muted
          >
            <source
              src={"https://select-clip.s3.amazonaws.com/select.mp4"}
              type="video/mp4"
            />
            Dont support video
          </video>
        </Box>
        {itemData.map((item, index) => (
          <ImageListItem
            key={item.img}
            cols={1}
            rows={1}
            className={`image-item ${isVisible ? "fadeInRotate" : ""}`}
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            <img {...srcset(item.img, 121, 1, 1)} alt="img" />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default Gallery;

const itemData = [
  {
    img: cl1,
  },
  {
    img: owners,
  },
  {
    img: cl3,
  },
  {
    img: cl4,
  },
  {
    img: cl6,
  },
  {
    img: shop,
  },
  {
    img: cl8,
  },
  {
    img: cl9,
  },
  {
    img: cl11,
  },
  {
    img: cl12,
  },
  {
    img: cl13,
  },
  {
    img: cl14,
  },
  {
    img: cl15,
  },
  {
    img: cl16,
  },
  {
    img: cl17,
  },
  {
    img: cl18,
  },

  {
    img: cl2_w,
  },
  {
    img: cl20,
  },
  {
    img: cl21,
  },
  {
    img: cl22,
  },
  {
    img: cl23,
  },
  {
    img: cl24,
  },
  {
    img: cl25,
  },
  {
    img: cl1_w,
  },
  {
    img: cl19,
  },

  {
    img: cl3_w,
  },
  {
    img: cl4_w,
  },
  {
    img: cl5_w,
  },
  {
    img: cl6_w,
  },
  {
    img: cl7_w,
  },
  {
    img: cl8_w,
  },
  {
    img: cl9_w,
  },
  {
    img: cl10_w,
  },
  {
    img: cl11_w,
  },
  {
    img: cl12_w,
  },
  {
    img: cl13_w,
  },
  {
    img: cl15_w,
  },
  {
    img: cl14_w,
  },
  {
    img: cl16_w,
  },
  {
    img: cl17_w,
  },
  {
    img: cl18_w,
  },
];
