import { useEffect, useState } from "react";
import { Box, ImageListItem } from "@mui/material";
const HorizontalCard = (props: any) => {
  const { title, description, image, reverse, id } = props;
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  return (
    <Box
      className={`horizontalCard`}
      sx={screenWidth > 1023 && reverse ? { flexDirection: "row-reverse" } : {}}
      id={id}
    >
      <h2 className="horizontalCard-mobileTitle">{title}</h2>
      <ImageListItem key={image}>
        <img
          srcSet={`${image}?w=480&h=500&fit=crop&auto=format&dpr=2 2x`}
          src={`${image}?w=480&h=500&fit=crop&auto=format`}
          alt={image}
          loading="lazy"
          className="horizontalCard-image"
        />
      </ImageListItem>

      <div className=" horizontalCard-content">
        <h2 className="horizontalCard-content-title">{title}</h2>
        <p className="horizontalCard-content-description">{description}</p>
      </div>
    </Box>
  );
};

export default HorizontalCard;
