import { Box, Typography } from "@mui/material";
import HorizontalCard from "../cards/HorizontalCard";
import {
  employee1,
  employee2,
  employee3,
} from "../../assets/images/allEmployees";

export const employees = [
  {
    image: employee1,
    title: "Jola",
    description:
      "Jola is the creative force behind stunning transformations at our salon. With 15 years in the industry, her innovative approach and keen eye for beauty make her a true artist. Jola’s passion for enhancing natural beauty and her ability to bring out the best in every client have earned her a reputation for excellence and creativity.",
    btn: true,
    id: "deliveryApp-section",
    app: "deliveryApp",
  },
  {
    image: employee3,
    title: "Elio",
    description:
      "Elio is the cornerstone of precision and mastery at Select Clip Barbershop & Salon. With 15 years of experience, his adeptness and meticulous attention to detail turn every grooming session into an art form. Known for his impeccable technique and warm demeanor, Elio ensures that each client leaves with a sense of renewed confidence and style.",
    btn: true,
    id: "deliveryApp-section",
    app: "deliveryApp",
  },

  {
    image: employee2,
    title: "Ledion",
    description:
      "Ledion, though relatively new to the team with 3 years of experience, brings a fresh and modern perspective to grooming. His dedication to his craft and innate sense of style have quickly made him a favorite among clients. Ledion’s youthful energy and commitment to perfection shine through in every service, making him an invaluable part of our team.",
    btn: false,
    app: "poroApp",
  },
];

export const Employees = () => {
  return (
    <Box className={"employee"} id={"employee-seciton"}>
      <Typography
        sx={{
          fontSize: "50px",
          fontWeight: "700",
          textAlign: "center",
          color: "#000",
          marginBottom: "50px",
          // marginTop: "50px",
        }}
      >
        Our Staff
      </Typography>
      <Box
        className="employee-horCard"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          gap: "172px",
          "@media (max-width: 768px)": {
            gap: "122px",
          },
        }}
      >
        {employees.map((item, i) => {
          const isEven = i % 2 === 0;
          return (
            <HorizontalCard
              title={item?.title}
              description={item?.description}
              image={item?.image}
              reverse={isEven ? true : false}
              key={i}
              id={item?.id}
              app={item.app}
            />
          );
        })}
      </Box>
    </Box>
  );
};
