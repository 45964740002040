import React, { useEffect, useRef } from "react";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";
import logo from "../../assets/svg/barber-shop-svgrepo-com.svg"; // Adjust the path as necessary
import { center } from "../../contants/mapConstants";
const containerStyle = {
  width: "100%",
  height: "400px",
};

const Map: React.FC = () => {
  const mapRef = useRef<google.maps.Map | null>(null);

  useEffect(() => {
    // Remove previous Google Maps API script if it exists
    const googleMapsScript = document.querySelector(
      'script[src*="maps.googleapis.com"]'
    );
    if (googleMapsScript) {
      googleMapsScript.remove();
    }
  }, []);

  return (
    <div className="map">
      <LoadScript
        googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
        loadingElement={<div>Loading...</div>}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={14}
          onLoad={(map) => {
            mapRef.current = map;
          }}
        >
          <MarkerF position={center} icon={logo} />
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Map;
