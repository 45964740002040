import { Box, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as Braids } from "../../assets/svg/braids.svg";
import { ReactComponent as Scissors } from "../../assets/svg/scissors.svg";
import { ReactComponent as Trimemr } from "../../assets/svg/trimmer.svg";
import { ReactComponent as Brush } from "../../assets/svg/brush.svg";
// import Brush from "../../assets/svg/Brush.tsx";
const Services = () => {
  const data = [
    {
      img: <Trimemr />,
      text: ["Hair Treatments", "Hair Styling", " Hair Coloring"],
    },

    {
      img: <Braids />,
      text: [" Beard Shave", "Beard Trim", " Fade", "Facial Clean"],
    },
    {
      img: <Scissors />,
      text: [`Men's Haircut`, `Women's Haircut`, `Kid's Haircut`],
    },
    {
      img: <Brush />,
      text: ["Hair Coloring", "Makeup Services"],
    },
  ];
  return (
    <Box
      id={"services-section"}
      sx={{
        position: "relative",
        paddingTop: "100px",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          transform: "translateY(100px)",
          marginTop: "-100px",
          borderBottomLeftRadius: "100px",
          borderBottomRightRadius: "100px",
          paddingBottom: "100px",
          bgcolor: "white",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "50px",
            fontWeight: "bold",
            position: "relative", // Ensure text is above pseudo-elements
            // zIndex: 2,
          }}
        >
          Services
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            "@media (max-width: 768px)": {
              flexDirection: "column",
              gap: "50px",
              alignItems: "center",
            },
            // Ensure children are above pseudo-elements
            // zIndex: 2,
          }}
        >
          {data.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "30px",
                  // Ensure items are above pseudo-elements
                  // zIndex: 2,
                }}
              >
                {item.img}
                <Box>
                  {item.text.map((text, i) => {
                    return (
                      <Typography
                        key={i}
                        sx={{
                          fontSize: "21px",

                          // zIndex: 2,
                        }}
                      >
                        {text}
                      </Typography>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Box
        sx={{
          position: "relatve",
          height: "200px",
          width: "100%",
          background: "black",
        }}
      ></Box>
    </Box>
  );
};

export default Services;
