import { Box, Button, Stack } from "@mui/material";
import Scroll from "react-scroll";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import logo from "../assets/images/homePage/logo.png";

export const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const ScrollLink = Scroll.Link;
  var scroll = Scroll.animateScroll;
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      if (screenWidth <= 1023) {
        setIsModalOpen(false);
      }
    };
  }, [screenWidth]);
  const handleGoHome = () => {
    scroll.scrollToTop();
    setIsModalOpen(false);
  };
  return (
    <Box className={`header ${isModalOpen ? "mobile" : ""}`}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={2}
        px={"64px"}
        sx={{
          "@media (max-width: 1023px)": {
            px: "28px",
          },
        }}
      >
        <Link
          className="header-logo"
          to={"/"}
          onClick={() => handleGoHome()}
          style={{
            textDecoration: "none",
          }}
        >
          <img
            alt="Select Clip"
            src={logo}
            style={{
              width: "200px",
              height: "auto",
            }}
          />
        </Link>

        <Stack
          direction={"row"}
          maxWidth={"688px"}
          width={"100%"}
          gap={"42px"}
          justifyContent={"flex-end"}
          sx={{
            "@media (max-width: 1023px)": {
              display: "none",
            },
          }}
        >
          <ScrollLink
            className="header-link"
            to={"employee-seciton"}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Our Staff
          </ScrollLink>
          <ScrollLink
            className="header-link"
            to={"services-section"}
            smooth={true}
            offset={-140}
            duration={500}
          >
            Services
          </ScrollLink>

          <ScrollLink
            className="header-link"
            to={"gallery-seciton"}
            smooth={true}
            offset={-130}
            duration={500}
          >
            Gallery
          </ScrollLink>
          <ScrollLink
            className="header-link"
            to={"findUs-section"}
            smooth={true}
            offset={-160}
            duration={500}
          >
            Find Us
          </ScrollLink>
        </Stack>
        <Button
          sx={{
            color: "#fff",
            "@media (min-width: 1023px)": {
              display: "none",
            },
          }}
          onClick={() => setIsModalOpen(!isModalOpen)}
        >
          {isModalOpen ? <CloseIcon /> : <MenuIcon />}
        </Button>
      </Stack>
      {isModalOpen && (
        <Box
          className={`mobile-list open`}
          style={{
            display: "flex",
            flexDirection: "column",
            zIndex: 1000,
            overflowY: "hidden",
          }}
          sx={{
            "@media (min-width: 1023px)": {
              display: "none",
            },
          }}
        >
          <ScrollLink
            onClick={() => setIsModalOpen(false)}
            className="header-link"
            to={"employee-seciton"}
            smooth={true}
            offset={-170}
            duration={500}
          >
            Our Staff
          </ScrollLink>
          <ScrollLink
            onClick={() => setIsModalOpen(false)}
            className="header-link"
            to={"services-section"}
            smooth={true}
            offset={-140}
            duration={500}
          >
            Services
          </ScrollLink>

          <ScrollLink
            onClick={() => setIsModalOpen(false)}
            className="header-link"
            to={"gallery-seciton"}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Gallery
          </ScrollLink>
          <ScrollLink
            onClick={() => setIsModalOpen(false)}
            className="header-link"
            to={"findUs-section"}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Find Us
          </ScrollLink>
          {/* <a
            href="https://web.poro.al/"
            style={{
              border: "1px solid #2F80ED",
              color: "#2F80ED",
              padding: "8px 14px",
              background: "transparent",
              borderRadius: "8px",
              textDecoration: "none",
            }}
          >
            Restorant Login
          </a> */}
        </Box>
      )}
    </Box>
  );
};
