import { Box, Button } from "@mui/material";
import selectClip from "../assets/images/homePage/logo.png";
import { Employees } from "../components/Home/Employees";
import Services from "../components/Home/Services";
import Gallery from "../components/Home/Gallery";

const Home = () => {
  return (
    <Box>
      <Box
        minHeight={"900px"}
        height={"100%"}
        className={"home"}
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          "@media (max-width: 768px)": {
            minHeight: "100vh",
          },
        }}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <img
            alt="Select Clip"
            src={selectClip}
            style={{
              width: "300px",
              height: "auto",
            }}
          />
          {/* <Button variant="contained">Appointmnet</Button> */}
        </Box>
      </Box>

      <Employees />
      <Services />

      <Gallery />
    </Box>
  );
};

export default Home;
